import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, Grid } from '@mui/material'
import Breadcrumbs from '../components/Breadcrumbs'
import RichText from '../components/RichText'
import PageHeader from '../components/layout/PageHeader'
import Content from '../components/Content'
import Seo from '../components/Seo'
import buildOgpTags from '../services/buildOgpTags'
import buildMetaData from '../services/buildMetaData'
import getText from '../services/text'
import addToSchema from '../services/addToSchema'


const ServiceTemplate = ({ data, pageContext }) => {
  const page = data.page || {}

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <RichText
            sx={{
              textAlign: 'center'
            }}
            content={page.data.title.richText}
          />
        </Grid>
        <Grid item xs={12}>
          <PageHeader icon={page.data.icon} subtitle={page.data.subtitle} />
        </Grid>
      </Grid>
    </Container>

    <Content doc={page} />
  </>
}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  // Other
  schema = addToSchema(schema, {
    "@context": "https://schema.org",
    "@type": "MedicalWebpage",
    "specialty": "https://schema.org/Dentist",
    "Name": page.data.title.text,
    "about": {
      "@type": "MedicalProcedure",
      "name": page.data.title.text
    },
    "MedicalAudience": "https://schema.org/Patient",
    "primaryImageOfPage":
    {
      "@type": "ImageObject",
      "Name": page.data.image.alt,
      "width": page.data.image.dimensions.width,
      "height": page.data.image.dimensions.height,
      "url": page.data.image.url,
      "representativeOfPage": "True"
    },

    "DateCreated": page.first_publication_date,
    "DatePublished": page.first_publication_date,
    "lastReviewed": page.last_publication_date,
    "text": getText(page),
  })


  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query serviceQuery($id: String, $lang: String) {
    page: prismicService(id: { eq: $id }, lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type 
      last_publication_date
      first_publication_date     
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          richText
          text
        }
        subtitle {
          richText
        }
        icon {
          alt
          gatsbyImageData(width: 100)
        }        

        image {
          alt
          dimensions{
            width
            height
          }
          gatsbyImageData
        }

        body {
          ... on PrismicServiceDataBodySimpleText {
            id
            primary {
              text {
                richText
                text
              }
            }
            slice_type
          }


          ... on PrismicServiceDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
                alt
              }
            }
          }


          ... on PrismicServiceDataBodyTextWithIcon {
            id
            slice_type
            primary {
              icon_image {
                gatsbyImageData
                alt
              }
              text {
                richText
                text
              }
            }
          }


          ... on PrismicServiceDataBodyWrappedImage {
            id
            slice_type
            slice_label
            primary {
              wrapped_image_image_size
              wrapped_image_text_size
              wrapped_image_text_background_full
              image_position
              wrapped_image {
                alt
                gatsbyImageData(width: 450)
              }
              wrapped_text {
                richText
                text
              }
            }
          }


          ... on PrismicServiceDataBodyWrappedVideo {
            id
            slice_type
            slice_label
            primary {
              wrapped_video_video_size
              wrapped_video_text_size
              wrapped_video_text_background_full
              video_position
              wrapped_video {
                embed_url
                title
                thumbnail_url
              }
              wrapped_text {
                richText
                text
              }
            }
          }



          ... on PrismicServiceDataBodyAd {
            id
            slice_type
            items {
              ad_size
              ad {
                document {
                  ... on PrismicAd {
                    id
                    type
                    uid
                    data {
                      title {
                        text
                      }
                      subtitle
                      url
                      left_upper_text {
                        richText
                      }
                      left_lower_text {
                        richText
                      }
                      image {
                        alt
                        gatsbyImageData(height: 255)
                      }
                    }
                  }


                  ... on PrismicBanner {
                    id
                    type
                    uid
                    data {
                      type
                      title {
                        text
                      }
                      subtitle                      
                      button_text
                      url
                      image {
                        alt
                        gatsbyImageData
                      }                      
                    }
                  }
                }
              }
            }
          }





          ... on PrismicServiceDataBodyCarousel {
            id
            slice_type
            items {
              slide_image {
                gatsbyImageData(width: 400)
                alt
              }
            }
            primary {
              content {
                text
                richText
              }
            }
          }



          ... on PrismicServiceDataBodyIntro {
            id
            slice_type
            primary {
              intro_headline {
                richText
              }
              intro_box_1_image {
                alt
                gatsbyImageData(width: 400)
              }
              intro_box_2_image {
                alt
                gatsbyImageData(width: 400)
              }
              intro_description {
                richText
              }
              intro_box_3_text {
                richText
              }
            }
          }


          ... on PrismicServiceDataBodyAboutUs {
            id
            slice_type
            primary {
              about_us_title {
                richText
              }
              about_us_description {
                richText
              }
              about_us_upper_left_text
              about_us_upper_right_text
              about_us_lower_left_text
              about_us_lower_right_text
            }
          }


        }
      }
    }
  }
`

export default ServiceTemplate